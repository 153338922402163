import createLucideIcon from '../createLucideIcon';

/**
 * @component @name Rabbit
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMjAgOC41NFY0YTIgMiAwIDEgMC00IDB2MyIgLz4KICA8cGF0aCBkPSJNMTggMjFoLThhNCA0IDAgMCAxLTQtNCA3IDcgMCAwIDEgNy03aC4yTDkuNiA2LjRhMS45MyAxLjkzIDAgMSAxIDIuOC0yLjhMMTUuOCA3aC4yYzMuMyAwIDYgMi43IDYgNnYxYTIgMiAwIDAgMS0yIDJoLTFjLTEuNyAwLTMgMS4zLTMgMyIgLz4KICA8cGF0aCBkPSJNNy42MSAxMi41M2EzIDMgMCAxIDAtMS42IDQuMyIgLz4KICA8cGF0aCBkPSJNMTMgMTZhMyAzIDAgMCAxIDIuMjQgNSIgLz4KICA8cGF0aCBkPSJNMTggMTJoLjAxIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/rabbit
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const Rabbit = createLucideIcon('Rabbit', [
  ['path', { d: 'M20 8.54V4a2 2 0 1 0-4 0v3', key: '49iql8' }],
  [
    'path',
    {
      d: 'M18 21h-8a4 4 0 0 1-4-4 7 7 0 0 1 7-7h.2L9.6 6.4a1.93 1.93 0 1 1 2.8-2.8L15.8 7h.2c3.3 0 6 2.7 6 6v1a2 2 0 0 1-2 2h-1c-1.7 0-3 1.3-3 3',
      key: '1i2yr3',
    },
  ],
  ['path', { d: 'M7.61 12.53a3 3 0 1 0-1.6 4.3', key: '9ygxkv' }],
  ['path', { d: 'M13 16a3 3 0 0 1 2.24 5', key: '1epib5' }],
  ['path', { d: 'M18 12h.01', key: 'yjnet6' }],
]);

export default Rabbit;
