import createLucideIcon from '../createLucideIcon';

/**
 * @component @name FileOutput
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNNCAyMmgxNGEyIDIgMCAwIDAgMi0yVjcuNUwxNC41IDJINmEyIDIgMCAwIDAtMiAydjQiIC8+CiAgPHBvbHlsaW5lIHBvaW50cz0iMTQgMiAxNCA4IDIwIDgiIC8+CiAgPHBhdGggZD0iTTIgMTVoMTAiIC8+CiAgPHBhdGggZD0ibTUgMTItMyAzIDMgMyIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/file-output
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const FileOutput = createLucideIcon('FileOutput', [
  ['path', { d: 'M4 22h14a2 2 0 0 0 2-2V7.5L14.5 2H6a2 2 0 0 0-2 2v4', key: '702lig' }],
  ['polyline', { points: '14 2 14 8 20 8', key: '1ew0cm' }],
  ['path', { d: 'M2 15h10', key: 'jfw4w8' }],
  ['path', { d: 'm5 12-3 3 3 3', key: 'oke12k' }],
]);

export default FileOutput;
